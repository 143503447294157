import React,{useEffect,useContext,useState,useRef} from 'react'
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import { Box,Tooltip,IconButton,FormControl, Radio, RadioGroup,
  FormControlLabel} from '@material-ui/core'
import Reporte from './Reporte'
import ReporteSN from './ReporteSNDatos'
import moment from 'moment';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Reloj from './Reloj';
import LinearProgress from '@material-ui/core/LinearProgress';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";
import { DatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers';
import {findNumber} from '../funciones/FindNumber'
import {llamadaApiToken,ErrorGeneral , llamaApiCargaToken } from '../funciones/ClienteAxios';
import Disponib from '../Disponi'
import SearchIcon from '@material-ui/icons/Search';

const Relojes2 = ({fechas,setFechas,opcionBit}) => {    
  const {inicio,final}=fechas  
  const guardarInicio = auxF =>
  {
    setFechas({...fechas, inicio : auxF })
  };  
  
  const guardarFinal = auxF =>
  {
    setFechas({...fechas, final : auxF })
  };

  return (
  <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>  
  <Box display={"flex"} flexDirection={"column"}> 
    <Box component="span"  >
      <Tooltip title="Seleccionar Fecha" >                
        <DatePicker
          disableFuture   format="dd/MM/yyyy"
          views={["year", "month", "date"]}
          label="Fecha" size="small"
          minDate="2021-12-02" maxDate={new Date()}
          value={inicio} onChange={guardarInicio}            
          style={{width:7+'rem'}} cancelLabel={"Cancelar"}                  
          okLabel="Seleccionar" 
        />                           
      </Tooltip>                                   
    </Box>  
   {opcionBit!=="dia"?
    <Box   component="span" width="25%">
      <DatePicker
        disableFuture id="final"
        format="dd/MM/yyyy" label="Fecha Final"
        views={["year", "month", "date"]}
        minDate={inicio} maxDate={new Date()}
        value={final} name="final"
        size="small" style={{width:6.5+'rem'}}      
        cancelLabel={"Cancelar"} okLabel="Seleccionar"    
        onChange={guardarFinal}
        minDateMessage={"La fecha final debe ser menor a Fecha inicial"}
      />
    </Box>
    :null}                  
    </Box> 
  </MuiPickersUtilsProvider>
  );
}

const RadioBitacora = ({opcion, setOpcion,opcionRef}) => {
  
  const handleRadioChange = (event) => {
    setOpcion(event.target.value);    
  };

  const handleRadioChange2 = (event) => {
    console.log(event.target.value);
    opcionRef.current=event.target.value;    
  };
  return (  
  <FormControl component="fieldset">    
    <RadioGroup row aria-label="position" name="opcion" defaultValue="top"
      onChange={handleRadioChange} value={opcion}
    >
      <FormControlLabel
        value="dia"  control={<Radio color="primary" />}
        label="Día" //labelPlacement="top"
      />
      <FormControlLabel
        value="rango"  control={<Radio color="primary" />}
        label="Rango" //labelPlacement="value"
      />                  
    </RadioGroup>
  </FormControl>      
  )
}

const RepoBitacoraRango = () => {
  const history = useHistory();
  const usu= localStorage.getItem('UsuarioNom');
  const nvl= localStorage.getItem('Tipo');    
  const usuID= localStorage.getItem('UsuId');         
  const source = axios.CancelToken.source();  
  const [datos,setDatos]=useState([])
  const [espera,setEspera]=useState(false)
  const [espera2,setEspera2]=useState(false)
  const {usuario,guardarUsuario}=useContext(AuthContext);
  const {guardarGenerales}=useContext(GeneralesContext);  
  const [listDispo,setListDispo]=useState([])
  const [buscar,setBuscar]=useState(false)
  const [fechas,setFechas]=useState({inicio:new Date(),final:new Date()})
  const [opcionBit, setOpcionBit] = useState('dia');
  const opcionRef = useRef('dia');
  const {inicio,final}=fechas
  
  useEffect(()=>{               
   
    veriSesion()
    datosBitacora()
   // datosDispo()
    if (opcionBit==='dia') 
    {
      datosDispo()
    }
    else{
      datosDispoRango()
    }

    return ()=> {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps	
  },[buscar])

  const veriSesion =  () => 
  {            
    if(usu===null)
    {                                
      guardarUsuario([])						
      guardarGenerales([])     
      localStorage.clear();           
      history.push("/")	
    }
    else
    { 
      if (usuario.length === 0) 
      {
        guardarUsuario({Nombre:usu,nivel:nvl});                                      
      }
      guardarGenerales({Titulo:'Reporte Bitácora'})                     
    }
  }

  const datosBitacora=()=>{
     
    const fInicio=moment(inicio).format("YYYY-MM-DD")   
    const fFin=moment(final).format("YYYY-MM-DD")                
    const data = qs.stringify({    
      Usuario:usuID,
      fechaIni:fInicio+'T00:00:00' ,
      fechaFin:(opcionBit==='dia'?fInicio: fFin)+'T23:59:59',                                  
      idDispositivo:'ifrekvjkxcdwdcwe'
    });
    const url =opcionBit==='dia'? "/reporte-bitacora-rango-1-1":"/reporte-bitacora-rango-full";
    function respuesta(aux) {
      if (aux[0].length!==0) 
      {                
        setDatos(aux[0]);                                      
      }          
    }

    llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
  }
  
  const datosDispoRango=()=>{
     
    const fInicio=moment(inicio).format("YYYY-MM-DD")        
    const fFin=moment(final).format("YYYY-MM-DD")                 
    const data = qs.stringify({    
      FechaIni:fInicio+'T00:00:00' ,      
      FechaFin:fFin+'T23:59:59',            
      idDispositivo:'ifrekvjkxcdwdcwe'
    });
    const url = "/reporte-bitacora-disponibilidad-intervalo";
    function respuesta(aux) {
      if (aux[0].length!==0) 
      {   setListDispo(aux);                                      
      }          
    }

    llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera2,history,source);
  }

  const datosDispo=()=>{
     
    const fInicio=moment(inicio).format("YYYY-MM-DD")     
    const fLim=new Date(moment( opcionBit === 'dia' ? inicio : final ).format("MM/DD/YYYY") )
    const fLimit=moment(inicio).format("MM")                
    const data = qs.stringify({    
      Fecha:fInicio+'T00:00:00' ,      
      idDispositivo:'ifrekvjkxcdwdcwe'
    });
    const url = "/reporte-bitacora-disponibilidad";
    function respuesta(aux) {
      if (aux[0].length!==0) 
      { //const filtro=aux.filter((disp)=>moment(disp.fecha).format("YYYY-MM-DD")===fInicio ||moment(disp.fecha).format("YYYY-MM-DD")===fAntes ) 
        const filtro=aux.filter((disp)=>new Date(moment.utc(disp.fecha).format("MM/DD/YYYY") )<= fLim &&  moment.utc(disp.fecha).format("MM")===fLimit)         
        setListDispo(filtro);                                      
      }          
    }

    llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera2,history,source);
  }

  function verificaReporte(auxJson){
    let auxValido=false;
    if (auxJson?.terminal !== undefined && auxJson.terminal[0].paros) {
      auxValido=true;
    //console.log('no esta vacio');
    } 
    else 
    {
      auxValido=false;
      //console.log('si esta vacio');
    }
    return auxValido;
  }

  const incrementarBuscar = e =>
  { // e.preventDefault();    
      
    setBuscar(!buscar)        
      
  };
  const urlReport=opcionBit==='dia'?"reports/BitacoraReport230602.mrt":"reports/Reporte-BitacoraIntervalo230602.mrt"
  return (
  <div>            
    <Box display="flex" flexDirection="row">
      <Box display="flex" width="25%" flexDirection="column" >
        <RadioBitacora opcion={opcionBit} setOpcion={setOpcionBit} opcionRef={opcionRef} />
        <Box display="flex" width="25%" flexDirection="row" mb={1} >        
          <Relojes2 fechas={fechas} setFechas ={setFechas } opcionBit={opcionBit} />
          <Box style={{ marginLeft:".5rem"}} display="flex" justifyContent="center">    
            <Tooltip title="Buscar reporte" >                          
              <IconButton color="inherit"  aria-label="add circle" 
                component="span" onClick={incrementarBuscar} 
              >
                <SearchIcon color="primary" style={{ fontSize: 40 }}  />
              </IconButton>            
            </Tooltip>  
          </Box>    
        </Box>
        {/* <span style={{backgroundColor:'red'}}>Dentro de la columna</span> */}
        {listDispo.length !== 0 ? <Disponib listDispo={listDispo} /> :null }        
      </Box>
      <Box display="flex" width="75%" >
        { !espera ? 
          datos.length !== 0 ?  ( verificaReporte(datos) ?
            <Reporte urlReport={urlReport} informacion={datos}
              nombre={' Reporte Bitacora'} mostrarTB={findNumber('|54|')?true:false  }
            />
          :<span>{<ReporteSN urlReport={urlReport} informacion={datos}
              nombre={' Reporte Bitacora'} mostrarTB={findNumber('|54|')?true:false }
            />/*   */ } </span>
            ) :<Reporte urlReport={urlReport} informacion={datos}
            nombre={' Reporte Bitacora'} mostrarTB={findNumber('|54|')?true:false }
          />
          :
          <Box style={{width:'100%'}} pt={2}>
            <LinearProgress color="primary" /> 
          </Box>
        }                
      </Box>
    </Box>                        
  </div>
  )
}

export default RepoBitacoraRango