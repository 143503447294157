import React,{useEffect} from 'react'
import {InputLabel,FormControl,Select as MaterialSelect  ,Box,
    TextField,RadioGroup,FormControlLabel,Radio,Typography,Checkbox
   } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '../Generales/ModalTam';
import axios from 'axios';
import qs from 'qs';
import Relojes from './Relojes'
import {toast} from 'react-toastify';
import {llamadaApiToken,llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
    },
  }));
function EditParoFalla({modalAbierto,setModalAbierto,titulo,paroSelect,recargaTabla}) {
    const classes = useStyles();
    let history = useHistory();
    const filter = createFilterOptions();
    const source = axios.CancelToken.source();      
    let idusu= localStorage.getItem('UsuId');
    const [espera,setEspera]=React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [aviso, setAviso] = React.useState(false);    
    const [mensaje, setMensaje] = React.useState('');
    const [tipoAdvertencia, setTipoAdvertencia] = React.useState('success');    
    const [listParoTipo, setListParoTipo] = React.useState([])
    const [listParoAla, setListParoAla] = React.useState([])
    const [listParoSolucion, setListParoSolucion] = React.useState([])
    const [listLugar, setListLugar] = React.useState([])
    const [listCategoria, setListCategoria] = React.useState([])
    const [listLugarTerminal, setListLugarTerminal] = React.useState([])
    const [listUbicacion, guardarListUbicacion] = React.useState([])
    let numUbiOtro=234;
    let hoy=new Date()
    const [nuevaFalla,guardarNuevaFalla]= React.useState({ParoId:0,
        Terminal:'',Usuario:'',Tipo:'',Ala:'',Lugar:'',Estado:'0',Categoria:'',CodError:'',cabina:'',
        Fecha:'',Horario:hoy,Informacion:'',SolucionId:'',Solucion:'',
        Tiempo:hoy,TipoDescr:'',Ubicacion:'',UbicDescr:'',TipoAlta:false,})
        const {Terminal,Usuario,Tipo,Ala,Lugar,Fecha ,Horario,Informacion,Categoria,CodError,
            cabina,SolucionId,Solucion,Tiempo,TipoDescr,Ubicacion,UbicDescr,Estado,ParoId,TipoAlta} = nuevaFalla;            
    
    useEffect(() => {                    
        let hoy = paroSelect.fecha;     
        let fecha = moment(hoy).format("YYYY-MM-DD");    
        let fecha2 = moment(hoy).format("YYYY-MM-DDTHH:mm:ss");             
        console.log(paroSelect);
        guardarNuevaFalla({ ...nuevaFalla,Fecha :fecha2,
          Horario: fecha +"T"+ paroSelect.hora , Terminal: paroSelect.termiId,
          Tiempo: fecha +"T"+ paroSelect.horaCierre , ParoId: paroSelect.Id ,
          Tipo: paroSelect.tipoId ,Ala: paroSelect.alaId , 
          Lugar: paroSelect.lugarId, Estado: paroSelect.Status.toString(),
          Informacion : paroSelect.info , SolucionId : paroSelect.solucId,
          Solucion : paroSelect.solucion , TipoDescr : paroSelect.tipoNom,
          Ubicacion : paroSelect.ubicId, UbicDescr : paroSelect.ubicDescr ,
          Categoria: paroSelect.categId , CodError : paroSelect.codError,
          cabina : paroSelect.cabina,
        })        
        
        return ()=> {
            source.cancel();
        }     
    }, [])

    useEffect(() => {
        const llenarListParo = () => {      
            let data = qs.stringify({              
              idDispositivo:'89y7gttvjhj98h'
            });
            let url = "/parofalla-tipo";
            function respuesta(aux) {
                if (aux[0].Id !== -1) 
                {  let auxArre=[];   
                    aux.map((tipo) => {
                        auxArre.push({ value : tipo.Id, label :tipo.Tipo })
                    })                    
                    setListParoTipo(auxArre);   
                }
            }
            
            llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)
        };

        const llenarListParoAla = () => {      
            let data = qs.stringify({              
              idDispositivo:'89y7gttvjhj98h'
            });
            let url = "/parofalla-ala";
            function respuesta(aux) {
              if (aux[0].Id !== -1) 
              {  let auxArre=[];   
                aux.map((tipo) => {
                  if (tipo.Id!=0) 
                  { auxArre.push({ value : tipo.Id, label :tipo.Tipo })  
                  }                  
                })                    
                setListParoAla(auxArre);    
              }
            }
            
            llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)
        };

        const llenarListParoSolucion = () => {      
            let data = qs.stringify({              
              idDispositivo:'89y7gttvjhj98h'
            });
            let url = "/parofalla-solucion";
            function respuesta(aux) {
              if (aux[0].Id !== -1) 
              {  let auxArre=[];   
                aux.map((tipo) => {
                  auxArre.push({ value : tipo.Id, label :tipo.Tipo })
                })                    
                setListParoSolucion(auxArre);       
              }
            }
            
            llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)
        };

        const llenarListLugar = () => {      
            let data = qs.stringify({ 
                tipo:'ORD',                 
              idDispositivo:'89y7gttvjhj98h'
            });
            let url = "/lugar-list-1_1";
            function respuesta(aux) {
              if (aux[0].Id !== -1) 
              { let auxArre=[];   
                aux.map((tipo) => {
                  if (tipo.Id!=0) 
                  { auxArre.push({ value : tipo.Id, label :tipo.Tipo })  
                  }            
                })                    
                setListLugar(auxArre);        
              }
            }
            
            llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)
        };

        const llenarListTerminal = () => {      
            let data = qs.stringify({    
                usuario:idusu,          
                idDispositivo:'89y7gttvjhj98h'
            });
            let url = "/lugar-terminal-list";
            function respuesta(aux) {
              if (aux[0].Id !== -1) 
              { let auxArre=[];   
                aux.map((tipo) => {
                    if (tipo.Id!=0) 
                    {  auxArre.push({ value : tipo.Id, label :tipo.Descr })  
                    }            
                })                    
                setListLugarTerminal(auxArre);            
              }
            }
            
            llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)
        };

        const llenarListCategoria = () => {      
            let data = qs.stringify({                         
                idDispositivo:'89y7gttvjhj98h'
            });
            let url = "/parofalla-categoria";
            function respuesta(aux) 
            {   if (aux[0].Id !== -1) 
                {   let auxArre=[];   
                    aux.map((tipo) => {
                        if (tipo.Id!=0) 
                        {  auxArre.push({ value : tipo.Id, label :tipo.Tipo })  
                        }            
                    })                                 
                    setListCategoria(auxArre);            
                }
            }
            
            llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)
        };

        function llenarUbicaciones() {
            const auxUbi= JSON.parse(localStorage.getItem('LisUbicOrd'));            
            let auxArre=[];
            auxUbi.map((ubica) => {
                if (ubica.LugarId==Lugar&& (ubica.Terminal.search(Terminal ) !== -1)) 
                {
                    auxArre.push({ value : ubica.Id, label :ubica.Tipo })  
                }            
            })        
            if (Lugar===5||Lugar==="5") 
            {
                guardarNuevaFalla({
                    ...nuevaFalla,
                    Ubicacion :numUbiOtro,
                    UbicDescr :( UbicDescr.length !== 0 ? UbicDescr: ''),
                }) 
            }
            guardarListUbicacion(auxArre)
        }

        if (listLugar.length === 0) 
        {
            llenarListLugar()  
        }
        if (listParoTipo.length === 0) 
        {
            llenarListParo();
        }
        if (listParoAla.length === 0) 
        {
            llenarListParoAla();
        }
        if (listParoSolucion.length === 0) 
        {
            llenarListParoSolucion()  
        }
        if (listLugarTerminal.length === 0) 
        {
            llenarListTerminal();  
        }
        
        if (Lugar !== '' &&Terminal !=='')   
        {
            llenarUbicaciones()
        }

        if (listCategoria.length === 0 ) 
        {
            llenarListCategoria()  
        }

    }, [Terminal,Lugar]) 

    const onChange = e =>
    {
      guardarNuevaFalla({
        ...nuevaFalla, [e.target.name] : e.target.value  
      })
    };

    const onChangeLugar = e =>
    {
      guardarNuevaFalla({
        ...nuevaFalla, 
        [e.target.name] : e.target.value  ,
        Ubicacion :0,
        UbicDescr :"",
      })
    };

    const guardaEstado = e =>
    {  
        guardarNuevaFalla({
            ...nuevaFalla, Estado : e.target.value  
        })        
    };

    const guardaUbicacion=(e) =>{                   
        const resultados= listUbicacion.filter(  (registro) => registro.value == e.target.value  )
        //console.log(resultados);
        if (resultados.length!=0) 
        {
            guardarNuevaFalla({
                ...nuevaFalla,
                Ubicacion :e.target.value  ,
                UbicDescr :resultados[0].label  ,        
            })  
        } 
        else 
        {   guardarNuevaFalla({
                ...nuevaFalla,
                Ubicacion :e.target.value  ,
                UbicDescr :''  ,        
            })  
        }
        
    }
    const guardaUbicacionInput = e =>
    {  
        guardarNuevaFalla({
             ...nuevaFalla,
            Ubicacion :numUbiOtro,
            UbicDescr :e.target.value    ,
        })        
    };

    const onChangeParo=(newValue)=>{
        //console.log(newValue);
        if (typeof newValue === 'string' && newValue ) {          
          guardarNuevaFalla({
            ...nuevaFalla,
            Tipo :newValue.value  ,
            TipoDescr:newValue.label,                
          })
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input          
          guardarNuevaFalla({
            ...nuevaFalla,
            Tipo :0 ,
            TipoDescr: newValue.inputValue.trim(),
            
          })
        } else {
          if (typeof newValue === 'object' ) {            
            if (newValue) {
              guardarNuevaFalla({
                ...nuevaFalla,
                Tipo :newValue.value  ,
                TipoDescr: newValue.label,     
              })            
            }else{
            guardarNuevaFalla({
              ...nuevaFalla,
              Tipo :''  ,
              TipoDescr: '',              
            })   }         
          }
          
        }
      }
      const cambioActualiza = (event) => {
        guardarNuevaFalla({
           ...nuevaFalla,[event.target.name]: event.target.checked 
          });
      };
    const registrarNvoParo=(auxHorario,auxTiempo)=>{
        const llenarNvoParo = () => {               
            let data = qs.stringify({
                ParoId:ParoId,
                Terminal:Terminal,
                Usuario:idusu,
                Tipo:TipoAlta ? 0 : Tipo,
                Ala:Ala,
                Lugar:Lugar, 
                Estatus:Estado,               
                Horario:auxHorario,
                Informacion: Informacion.length!== 0 ? Informacion : '',
                SolucionId:( ( SolucionId === 0 || SolucionId !== '' ||SolucionId !== '' ) && Solucion !== '' && 
                            ( Solucion !== undefined && SolucionId !== undefined ) )  ? SolucionId : 0 ,
                Solucion: (( SolucionId===0 || SolucionId !== '' || SolucionId !== '' ) && Solucion !== '' && 
                            ( Solucion !== undefined && SolucionId !== undefined ) )  ? Solucion : '',
                TipoDescr:TipoDescr,
                TipoAlta:TipoAlta ? 1 : 0,
                UbicDescr:UbicDescr,
                UbicId:Ubicacion,
                CodError:CodError,
                Cabina:cabina,
                Categoria:Categoria,
                HorarioCierre:auxTiempo,
                idDispositivo:'iu87ygubhjjij',
            });   
            let url = "/parofalla-edit-1-1";
            function respuesta(aux) {
                if (aux.respuesta === 1) 
                {   let mensaje="Se edito correctamente el Paro/Falla "+ ParoId;
                    setTipoAdvertencia("success")      
                    setMensaje(mensaje);
                    setAviso(true)           
                    //setRegistroExitoso(true) 
                    toast.success(mensaje,{
                        pauseOnFocusLoss: false,                    
                        toastId: `nvo-Paro-Falla${ParoId}`
                    })
                    recargaTabla()
                    setModalAbierto(false)
                }       
            }    
            llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
        }            
        llenarNvoParo();    
    }  

    const mostrarAlertas =(auxMensaje)=>{ 
        setTipoAdvertencia("warning")
        let mensaje=auxMensaje            
        setMensaje(mensaje);
        setAviso(true)   
    } 

    function valNulos(auxMensaje,auxCampo) 
    {    let valida=false    
        if ( auxCampo !== '' && auxCampo !== '0' && auxCampo !==0 
            && auxCampo !== null && auxCampo!==" " ) 
        {   valida=true              
        }
        if (!valida) 
        {   mostrarAlertas(auxMensaje)
        }
        return valida
    }

    const guardarParo = () => {
        if ( valNulos("Seleccionar Terminal",Terminal ) && valNulos("Seleccionar Lugar",Lugar )
             && valNulos("Seleccionar Ubicación",Ubicacion) && valNulos("Seleccionar Ubicación",UbicDescr)&& valNulos("Seleccionar Hora de paro",Horario)
             && valNulos("Seleccionar Sección",Ala ) && valNulos("Seleccionar Categoria",Categoria) 
             && valNulos("Seleccionar Hora de paro",Horario)
             && valNulos("Seleccionar Hora de Cierre",Tiempo)
             && (Tipo===0 && TipoDescr.length!==0 ? true: valNulos("Seleccionar Paro/Falla",Tipo))
            && valNulos("Seleccionar Paro/Falla",TipoDescr)
        ) 
        {                          
            let HoraParo = moment(Horario).format("HH:mm:ss");
            let HoraCierre = moment(Tiempo).format("HH:mm:ss");    
            //let dia=moment()          
            registrarNvoParo(HoraParo,HoraCierre)                                       
        }                                
    };

    const contenido=()=>{
        return(
        <form className={classes.root} noValidate autoComplete="off" fullWidth>                    
        { listLugarTerminal.length !== 0 ?  
            <FormControl style={{width:7.5+'rem'}}size="small">
                <InputLabel htmlFor="age-native-simple">Terminal</InputLabel>
                <MaterialSelect native value={Terminal}
                    onChange={onChangeLugar}
                    inputProps={{ name: 'Terminal', id: 'Terminal', }}
                >
                    <option aria-label="None" value="" />    
                    {listLugarTerminal.map((row,index) => (
                        <option key={index} value={row.value}>{row.label}</option>
                    ))}   
                </MaterialSelect>
            </FormControl>     
        :null}        
        { listLugar.length !== 0 ? <FormControl  size="small" style={{width:7+'rem'}}>
            <InputLabel htmlFor="age-native-simple"> Lugar </InputLabel>
            <MaterialSelect native value={Lugar}
                onChange={onChangeLugar} style={{width:6.5+'rem'}}
                inputProps={{ name: 'Lugar', id: 'Lugar', }}
            >
                <option aria-label="None" value="" />        
                {listLugar.map((row, index) => (
                    <option key={index} value={row.value}>{row.label}</option>
                ))}   
            </MaterialSelect>
        </FormControl>
        :null
        }
       
        { listUbicacion.length !== 0 ?  
            ( Lugar !== 5 && Lugar !== "5" ?  
            <FormControl style={{width:6.5+'rem'}} size="small">
                <InputLabel htmlFor="age-native-simple">Ubicación</InputLabel>
                <MaterialSelect native value={Ubicacion}
                    onChange={guardaUbicacion}
                    inputProps={{ name: 'Ubicacion', id: 'Ubicacion', }}
                >
                    <option aria-label="None" value="" />        
                    {listUbicacion.map((row,index) => (
                        <option key={index} value={row.value}>{row.label}</option>
                    ))}   
                </MaterialSelect>
                </FormControl>
                :  <TextField id="outlinedUbicacion" label="Ubicación"  size="small"
                        value={UbicDescr} onChange={guardaUbicacionInput}  
                        name="UbicDescr"                                               
                    />  
            )
        :   null}
         { listParoAla.length !== 0 ?  
            <FormControl style={{width:6.5+'rem'}} size="small">
                <InputLabel htmlFor="Ala">Sección</InputLabel>
                <MaterialSelect native value={Ala}
                    onChange={onChange}
                    inputProps={{ name: 'Ala', id: 'Ala', }}
                >                    
                    <option aria-label="None" value="" />
                    { listParoAla.map((row,index) => (
                        <option key={index} value={row.value}>{row.label}</option>
                    ))}                   
                </MaterialSelect>
            </FormControl>           
        :null}
        { listCategoria.length !== 0 ?  
            <FormControl style={{width:9.5+'rem'}} size="small">
                <InputLabel htmlFor="Categoria">Categoría</InputLabel>
                <MaterialSelect native value={Categoria}
                    onChange={onChange}
                    inputProps={{ name: 'Categoria', id: 'Categoria', }}
                >                    
                    <option aria-label="None" value="" />
                    { listCategoria.map((row,index) => (
                        <option key={index} value={row.value}>{row.label}</option>
                    ))}                   
                </MaterialSelect>
            </FormControl>           
        :null}
        <br/>         
        <FormControl component="fieldset" style={{width:30+'rem'}}>                  
            <RadioGroup  row aria-label="position"  defaultValue="top"
                name="Estado" value={Estado} onChange={guardaEstado}
            >
                <FormControlLabel value='0' style={{color:'#F46239'}} 
                    control={<Radio color="primary" />} label="Registrado" />    
                <FormControlLabel value='1'  style={{color:'#049b4f'}} 
                    control={<Radio color="primary" />} label="Solucionado" />
                <FormControlLabel value='-1' style={{color:'red'}} 
                    control={<Radio color="primary" />} label="Cancelado" />                
            </RadioGroup>                     
        </FormControl>        
        <br/>       
        <Relojes nuevaFalla={nuevaFalla} guardarNuevaFalla={guardarNuevaFalla}/>         
        <TextField id="outlinedReferen"           
            label="Cabina"  size="small" value={cabina} 
            onChange={onChange} name="cabina" style={{width:8+'rem'}}                                               
          />                               
        <TextField id="outlinedReferen"           
            label="Codigo Error"  size="small" value={CodError} 
            onChange={onChange} name="CodError" style={{width:8+'rem'}}                                               
          />             
          <br/>
          <Box display="flex" flexDirection="row">
            <Box>
                <FormControlLabel label="Agregar" size="small" style={{padding:0,margin:0}}
                    control={
                        <Checkbox checked={TipoAlta} onChange={cambioActualiza} 
                            name="TipoAlta" size="small" />}            
                />
            </Box>
            <Box justifyContent={"center"}>
                <Typography component="p" variant="body1" color='primary' 
                    style={{marginTop:.5+'rem' , marginLeft:1+'rem',width:10+'rem'}} >
                    Paro y/ó Falla
                </Typography>
            </Box>
        </Box>
           
        <Autocomplete value={TipoDescr}  
            onChange={(event, newValue) =>onChangeParo(newValue) }
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // Suggest the creation of a new value
                if (params.inputValue.trim() !== '') {
                filtered.push({
                    inputValue: params.inputValue,
                    label: `Agregar "${params.inputValue}"`,
                });
                }
                return filtered;
            }} 
            selectOnFocus clearOnBlur handleHomeEndKeys
            id= 'AutoSelect-Solucion' options={listParoTipo}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.label;
            }}
            renderOption={(option) => option.label} style={{ width: 900 }}
            loading={listUbicacion.length!=0 } freeSolo size="small"
            color="primary"
            renderInput={(params) => (
                <TextField {...params}  variant="outlined" 
                    label="Selecciona una descripción de Paro y/o Fallas" />
            )}
        />
        <Autocomplete value={Solucion}
            onChange={(event, newValue) => {    
                event.preventDefault()    
                if (typeof newValue === 'string' && newValue ) 
                {   guardarNuevaFalla({
                        ...nuevaFalla, SolucionId :newValue.value  ,
                        Solucion: newValue.label,            
                    })
                } 
                else
                {   if (newValue && newValue.inputValue) 
                    {   // Create a new value from the user input          
                        guardarNuevaFalla({
                            ...nuevaFalla, SolucionId :0 ,
                            Solucion: newValue.inputValue.trim(),            
                        })
                    } 
                    else 
                    {   if (typeof newValue === 'object' ) 
                        {   if (newValue) 
                            {   guardarNuevaFalla({
                                    ...nuevaFalla, SolucionId :newValue.value  ,
                                    Solucion: newValue.label,     
                                })            
                            }
                            else
                            {   guardarNuevaFalla({
                                    ...nuevaFalla, SolucionId :'', Solucion: '',              
                                })   
                            }         
                        }          
                    }
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // Suggest the creation of a new value
                if (params.inputValue.trim() !== '') 
                {   filtered.push({
                        inputValue: params.inputValue,
                        label: `Agregar "${params.inputValue}"`,
                    });
                }
                return filtered;
            }}
            selectOnFocus clearOnBlur handleHomeEndKeys
            id= 'AutoSelect-Solucion' options={listParoSolucion}
            getOptionLabel={(option) => {
            // Value selected with enter, right from the input
                if (typeof option === 'string') 
                {   return option;
                }
            // Add "xxx" option created dynamically
                if (option.inputValue) 
                {   return option.inputValue;
                }
            // Regular option
                return option.label;
            }}
            renderOption={(option) => option.label} style={{ width: 900 , marginTop:2+'rem'}}
            freeSolo size="small" color="primary"
            renderInput={(params) => (
                <TextField {...params} label="Escriba una solución" variant="outlined" />
            )}
        /> 
        <Box>
        <Typography component="p" variant="body2" color='primary' 
            style={{margin:0,marginLeft:.5+'rem'}} >
            Información Adicional
        </Typography>                             
        <textarea id="Informacion" name="Informacion" rows="5"
            cols="100" placeholder="Información Adicional" style={{width: 56+"rem"}} 
            value={Informacion} onChange={onChange}  maxlength={1000}
        />
        <Box display={"flex"} justifyContent="flex-start" width={"100%"} >
        <Typography variant="body" color="initial" >{Informacion.length}</Typography>
      </Box>
      </Box>
        <br/>       
            
    </form>
    )
    }

  return (
    <Modal
      titulo={titulo} modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto} guardarDatos={guardarParo}
      mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
      aviso={aviso} setAviso={setAviso} espera={espera}
      setEspera={setEspera} tamanio={"md"}
    >
        {contenido()}
    </Modal>
  )
}

export default EditParoFalla